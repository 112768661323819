body {
  font-family: "Roboto", sans-serif;
  line-height: 24px;
  background-image: linear-gradient(
    to top left,
    rgb(143, 143, 143),
    rgb(252, 252, 252)
  );
}

svg {
  fill: white;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

/* Mobile screen! Hide desktop navbar */
@media screen and (min-width: 0px) and (max-width: 1250px) {
  #desktop-navbar {
    display: none;
  }

  #desktop-homepage {
    display: none;
  }

  #contact-us-break {
    display: block;
    flex-basis: 100%;
    height: 0;
  }

  #contact-us-form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  #contact-us-map {
    width: 100%;
    height: 75vw;
  }

  #mobile-navbar {
    display: block;
  }

  #mobile-homepage {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    width: 100%;
  }

  #mtl-title-1 {
    font-size: 6vw;
    letter-spacing: 1;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
  }

  #mtl-title-2 {
    font-style: italic;
    font-size: 5vw;
  }
}

/* Desktop screen! Show desktop navbar */
@media screen and (min-width: 1251px) {
  #desktop-navbar {
    display: flex;
  }

  #desktop-homepage {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    width: 100%;
  }

  #contact-us-mobile-break {
    display: none;
  }

  #mobile-navbar {
    display: none;
  }

  #mobile-homepage {
    display: none;
  }

  #contact-us-break {
    display: none;
  }

  #contact-us-form {
    display: flex;
    flex-direction: column;
    width: 49%;
  }

  #contact-us-map {
    display: flex;
    width: 49%;
  }

  #mtl-title-1 {
    font-size: 50pt;
    letter-spacing: 1;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
  }

  #mtl-title-2 {
    font-style: italic;
    font-size: 40pt;
  }
}
/* END OF MEDIA SECTION */

#minimalistic-desktop {
  text-align: center;
  width: 100%;
  margin-bottom: 8em;
}

img {
  max-width: 100%;
  max-height: auto;
}

.btn-reg {
  letter-spacing: 0.1em;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 45px;
  max-width: 160px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
}
.btn-reg:hover {
  text-decoration: none;
}

.effect04 {
  --uismLinkDisplay: var(--smLinkDisplay, inline-flex);
  display: var(--uismLinkDisplay);
  color: #000;
  outline: solid 2px #000;
  position: relative;
  transition-duration: 0.4s;
  overflow: hidden;
}

.effect04::before,
.effect04 span {
  margin: 0 auto;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-duration: 0.4s;
}

.effect04:hover {
  background-color: #000;
}

.effect04:hover span {
  -webkit-transform: translateY(-400%) scale(-0.1, 20);
  transform: translateY(-400%) scale(-0.1, 20);
}

.effect04::before {
  content: attr(data-sm-link-text);
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-transform: translateY(500%) scale(-0.1, 20);
  transform: translateY(500%) scale(-0.1, 20);
}

.effect04:hover::before {
  letter-spacing: 0.05em;
  -webkit-transform: translateY(0) scale(1, 1);
  transform: translateY(0) scale(1, 1);
}

/* Section for martin */
body,
html {
  font-family: "Roboto", sans-serif;
}

.router-div {
  padding-top: 2%;
  padding-bottom: 2%;
  min-height: calc(100vh - 400px);
}

/* The hero image */
.hero-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./assets/images/KSdT41j.jpg");
  height: 20%;
  background-position: 0% 20%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  position: absolute;
  top: 30%;
  left: 25%;
  transform: translate(-50%, -50%);
  color: white;
}

.wrapper-two {
  display: flex;
  flex-wrap: wrap;
}

.logo-flex-box-1 {
  margin-top: 10px;
  margin-left: 5%;
}

.logo-flex-box-2 {
  margin-top: 15px;
  margin-left: 5%;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.wrapper {
  margin: auto;
  display: flex;
  align-items: center;
}

.wrapper-home {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.wrapper-home-mobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.nav-item {
  padding-left: 1.5em;
}

.category-home-description {
  padding: 0 5% 0 5%;
  height: fit-content;
  width: 100%;
}

footer .main-footer {
  padding: 20px 0;
  background: #252525;
}
footer ul {
  padding-left: 0;
  list-style: none;
}

/* Copy Right Footer */
.footer-copyright {
  background: #222;
  padding: 5px 0;
}
.footer-copyright .logo {
  display: inherit;
}
.footer-copyright nav {
  float: right;
  margin-top: 5px;
}
.footer-copyright nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer-copyright nav ul li {
  border-left: 1px solid #505050;
  display: inline-block;
  line-height: 12px;
  margin: 0;
  padding: 0 8px;
}
.footer-copyright nav ul li a {
  color: #969696;
}
.footer-copyright nav ul li:first-child {
  border: medium none;
  padding-left: 0;
}
.footer-copyright p {
  color: #969696;
  margin: 2px 0 0;
}

/* Footer Top */
.footer-top {
  background: #252525;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 3px solid #222;
}

/* Footer transparent */
footer.transparent .footer-top,
footer.transparent .main-footer {
  background: transparent;
}
footer.transparent .footer-copyright {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.3);
}

/* Footer light */
footer.light .footer-top {
  background: #f9f9f9;
}
footer.light .main-footer {
  background: #f9f9f9;
}
footer.light .footer-copyright {
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0.3);
}

/* Footer 4 */
.footer- .logo {
  display: inline-block;
}

/*==================== 
    Widgets 
  ====================== */
.widget {
  padding: 20px;
  margin-bottom: 40px;
}
.widget.widget-last {
  margin-bottom: 0px;
}
.widget.no-box {
  padding: 0;
  background-color: transparent;
  margin-bottom: 40px;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
}
.widget.subscribe p {
  margin-bottom: 18px;
}
.widget li a {
  color: #ff8d1e;
}
.widget li a:hover {
  color: #4b92dc;
}
.widget-title {
  margin-bottom: 20px;
}
.widget-title span {
  background: #839fad none repeat scroll 0 0;
  display: block;
  height: 1px;
  margin-top: 25px;
  position: relative;
  width: 20%;
}
.widget-title span::after {
  background: inherit;
  content: "";
  height: inherit;
  position: absolute;
  top: -4px;
  width: 50%;
}
.widget-title.text-center span,
.widget-title.text-center span::after {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
.widget .badge {
  float: right;
  background: #7f7f7f;
}

.typo-light h1,
.typo-light h2,
.typo-light h3,
.typo-light h4,
.typo-light h5,
.typo-light h6,
.typo-light p,
.typo-light div,
.typo-light span,
.typo-light small {
  color: #fff;
}

ul.social-footer2 {
  margin: 0;
  padding: 0;
  width: auto;
}
ul.social-footer2 li {
  display: inline-block;
  padding: 0;
}
ul.social-footer2 li a:hover {
  background-color: #ff8d1e;
}
ul.social-footer2 li a {
  display: block;
  height: 30px;
  width: 30px;
  text-align: center;
}
.btn-reg {
  background-color: #ff8d1e;
  color: #fff;
}
.btn-reg:hover,
.btn-reg:focus,
.btn-reg.active {
  background: #4b92dc;
  color: #fff;
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 250ms ease-in-out 0s;
  -moz-transition: all 250ms ease-in-out 0s;
  -ms-transition: all 250ms ease-in-out 0s;
  -o-transition: all 250ms ease-in-out 0s;
  transition: all 250ms ease-in-out 0s;
}
.color-change {
  width: 100%;
  text-align: center;
  font-size: 18pt;
  color: #f35626;
  animation: color-changing 5s infinite linear;
}

@keyframes color-changing {
  from {
    filter: hue-rotate(0deg);
  }
  to {
    filter: hue-rotate(360deg);
  }
}

.mechanical-testing-home {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-evenly;
}

.mechanical-testing-home-div {
  line-height: 1.6;
  max-width: 40%;
  padding: 5px;
  margin: 5px;
}

h3 {
  color: #333333;
  font-size: medium;
  font-weight: 500;
  line-height: 1.1;
  margin-top: 0;
}

.image-vib-right {
  padding-bottom: 2em;
  display: flex;
  gap: 25px;
  margin-top: 2%;
  flex-direction: column;
}

.image-vib-center {
  padding-bottom: 2em;
  display: flex;
  gap: 25px;
  margin-top: 2%;
  flex-direction: column;
}

.image-vib-left {
  padding-bottom: 2em;
  display: flex;
  margin-top: 2%;
  flex-direction: column;
}

table {
  border-collapse: collapse;
  width: 100%;
  font-size: 15pt;
}

td,
th {
  /* border: 1px solid #dddddd; */
  text-align: left;
  padding: 8px;
}

.ulStandards {
  columns: 3;
  margin: 0 auto;
  text-align: left;
}

.liStandards {
  display: inline-block;
  vertical-align: top;
}

.olSpecify {
  line-height: 1.4;
}

.liSpecify {
  display: list-item;
  list-style-type: inherit;
}
